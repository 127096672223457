import React from "react";
import Tokenomics from "./components/tokenomics/Tokenomics";
import SmartContract from "./components/smart-contract/SmartContract";
import "./TokenomicsPage.scss";

const TokenomicsPage = () => {
    return (
        <div className="tokenomics-page white-color">
            <Tokenomics />
            <SmartContract />
        </div>
    );
};

export default TokenomicsPage;
