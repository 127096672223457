import { ReactComponent as Community } from "../../assets/community-1.svg";
import { ReactComponent as Marketing } from "../../assets/marketing.svg";
import { ReactComponent as Airdrops } from "../../assets/airdrops-1.svg";
import { ReactComponent as Advisors } from "../../assets/advisors-1.svg";
import { ReactComponent as Treasury } from "../../assets/treasury-1.svg";
import { ReactComponent as Burn } from "../../assets/burn.svg";

export const tokenomicsCards = [
  {
    id: 1,
    icon: <Community />,
    value: 19000000,
    label: "19.000.000",
    name: "Community",
  },
  {
    id: 2,
    icon: <Marketing />,
    value: 3000000,
    label: "3.000.000",
    name: "Marketing",
  },
  {
    id: 3,
    icon: <Airdrops />,
    value: 2000000,
    label: "2.000.000",
    name: "AirDrops",
  },
  {
    id: 4,
    icon: <Advisors />,
    value: 1000000,
    label: "1.000.000",
    name: "Advisors",
  },
  {
    id: 5,
    icon: <Treasury />,
    value: 10000000,
    label: "10.000.000",
    name: "Treasury",
  },
  {
    id: 6,
    icon: <Burn />,
    value: 65000000,
    label: "65.000.000",
    name: "Burn",
    backgroundColor: "#FF7D8433",
  },
];

export const pieChartData = {
  labels: ["Community", "Marketing", "AirDrops", "Advisors", "Treasury"],
  datasets: [
    {
      data: tokenomicsCards.map(({ value, name }) => name !== "Burn" && value),
      backgroundColor: ["#3BB8D0", "#DE51A8", "#A499F5", "#5472E4", "#AE8C7E"],
      borderColor: "#000000",
      borderWidth: 3,
    },
  ],
};
