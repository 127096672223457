import { tokenomicsCards, pieChartData } from "./Tokenomics.mock.data";
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import SumIcon from "../../assets/sum-icon.png";
import InitialTotalSupply from "../../assets/initial-total-supply.png";

ChartJS.register(ArcElement, Tooltip);

const Tokenomics = () => {
    const options = {
        plugins: {
            legend: {
                display: false,
            },
            // tooltip: { enabled: false },
        },
    };

    // Custom plugin to display percentage text on pie slices
    const showPercentagePlugin = {
        id: "showPercentage",
        afterDatasetsDraw: (chart) => {
            const { ctx, data } = chart;
            if (!ctx || !data?.datasets[0]?.data) return;

            const sliceData = data.datasets[0].data.slice(
                0,
                data.datasets[0].data?.length - 1
            );

            const total = sliceData.reduce((acc, val) => acc + val, 0);

            // Adjust this to control the distance of the text from the arc's edge
            const textOffset = 30;

            ctx.save(); // Save the current state
            ctx.font = "bold 18px Inter-Bold, sans-serif";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "#ffffff";

            sliceData.forEach((value, i) => {
                // Calculate percentage
                const percentage = ((value / total) * 100).toFixed(0) + "%";

                // Get metadata and calculate the angle of each slice's midpoint
                const meta = chart.getDatasetMeta(0);
                const arc = meta.data[i];

                // Use getProps to access startAngle, endAngle, x, and outerRadius
                const { startAngle, endAngle, x, y, outerRadius } =
                    arc.getProps(
                        ["startAngle", "endAngle", "x", "y", "outerRadius"],
                        true
                    );

                // Calculate midpoint angle for positioning
                const angle = (startAngle + endAngle) / 2;

                // Calculate x and y position with offset outside the edge of the arc
                const textX = x + (outerRadius - textOffset) * Math.cos(angle);
                const textY = y + (outerRadius - textOffset) * Math.sin(angle);

                // Draw the percentage text with added offset
                ctx.fillText(percentage, textX, textY);
            });

            ctx.restore();
        },
    };

    return (
        <div className="w-100 tokenomics-section px-4 px-sm-6">
            <div className="container-1280 mx-auto">
                <h1 className="tokenomics-title font-size-64 font-iner-bold line-height-1-1">
                    Tokenomics
                </h1>

                <div className="w-100 tokenomics-container d-flex justify-content-between align-items-center gap-6 gap-xl-12">
                    <div
                        className="d-flex flex-column gap-sm-4 gap-3 left-side"
                        style={{ width: "628px", maxWidth: "100%" }}
                    >
                        <div className="w-100 total-supply d-flex align-items-center gap-2 gap-xxs-4">
                            <img src={SumIcon} alt="sum-icon" />
                            <div className="d-flex flex-column gap-1">
                                <p className="mb-0 font-size-40 font-iner-bold line-height-1-1">
                                    35.000.000
                                </p>
                                <p
                                    style={{ color: "rgba(255,255,255,0.5)" }}
                                    className="mb-0 font-size-18 font-iner-regular line-height-1-1"
                                >
                                    Minted Total Supply
                                </p>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap gap-sm-4 gap-3 w-100">
                            {tokenomicsCards?.map(
                                ({
                                    id,
                                    icon,
                                    name,
                                    label,
                                    backgroundColor,
                                }) => (
                                    <div
                                        key={id}
                                        className="tokenomics-card d-flex justify-content-center align-items-center flex-grow-1"
                                        style={{
                                            backgroundColor: backgroundColor
                                                ? backgroundColor
                                                : "#1A1A1A",
                                        }}
                                    >
                                        <div className=" d-flex align-items-center gap-4">
                                            {icon}
                                            <div className="d-flex flex-column gap-1">
                                                <p className="mb-0 font-size-28 font-iner-bold line-height-1-1">
                                                    {label}
                                                </p>
                                                <p
                                                    style={{
                                                        color: "rgba(255,255,255,0.5)",
                                                    }}
                                                    className="mb-0 font-size-18 font-iner-regular line-height-1-1"
                                                >
                                                    {name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                        <div className="w-100 initial-total-supply d-flex align-items-center gap-2 gap-xxs-4">
                            <img src={InitialTotalSupply} alt="sum-icon" />
                            <div className="d-flex flex-column gap-1">
                                <p className="mb-0 font-size-28 font-iner-bold line-height-1-1">
                                    100.000.000
                                </p>
                                <p
                                    style={{ color: "rgba(255,255,255,0.5)" }}
                                    className="mb-0 font-size-18 font-iner-regular line-height-1-1"
                                >
                                    Initial Total Supply
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="pie-chart w-100 d-flex justify-content-center align-items-center">
                        <Pie
                            data={pieChartData}
                            options={options}
                            plugins={[showPercentagePlugin]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tokenomics;
