import React from "react";
import Hero from "./HomepageHero/HomepageHero";
import Footer from "../../Components/Footer/Footer";
import DigitalChip from "./DigitalChip/DigitalChip";
import FirstProject from "./FirstProject/FirstProject";
import PlayWinEarn from "./PlayWinEarn/PlayWinEarn";
import Tokenomics from "./Tokenomics/Tokenomics";
import PlayGround from "./PlayGround/PlayGround";
import PerfectStrategy from "./PerfectStrategy/PerfectStrategy";
import BuiltForUsers from "./BuiltForUsers/BuiltForUsers";
import PlatformHelp from "../Platform/PlatformHelp/PlatformHelp";
import "./Homepage.scss";

const Homepage = () => {
    return (
        <div className="homepage white-color s">
            {/* <Hero /> */}
            {/* <JoinBanner /> */}
            {/* <SecretClub/> */}
            <Hero />
            <FirstProject />
            <DigitalChip />
            <PlayWinEarn />
            <PerfectStrategy />
            <Tokenomics />
            <PlayGround />
            <BuiltForUsers />
            {/* <SuccessBusiness /> */}

            {/* <LatestNews /> */}

            {/* <Performance />
      <Incentive />
      <RankingSystem />
      <NFTSection />
      <DiscoverNft />
      <UnlockMedals />
  MoreThanSales /> */}
            {/* <Networking />
      <MakeADifference />
      <Register /> */}
            <PlatformHelp />
            <Footer />
        </div>
    );
};

export default Homepage;
