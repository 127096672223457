import { toast } from "react-toastify";
import Button from "../../../../Components/Button/Button";
import { ReactComponent as CopyIcon } from "../../assets/copy-icon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as BadgeIcon } from "../../assets/badge-icon.svg";

const SmartContract = () => {
    const handleCopyMessage = () => {
        toast.success("Smart Contact Address was copied successfully!", {
            toastId: "copy-information",
        });
    };
    return (
        <div className="w-100 smart-contract-section px-4 px-sm-6">
            <div className="container-1280 mx-auto">
                <div className=" w-100 smart-contract-card">
                    <div className="badge">
                        <BadgeIcon />
                        <span className="font-iner-semibold font-size-14">
                            Chain: XDEN
                        </span>
                    </div>
                    <label className="font-iner-medium font-size-16 mb-2">
                        Smart Contact Address
                    </label>
                    <div className="position-relative">
                        <input
                            placeholder=""
                            value={"0x42EC8d1eB7CD9d3EcC91989DF6a093735B3A2F33"}
                            disabled
                            type="text"
                            className="input-rnf input-address"
                        />
                        <CopyToClipboard
                            text={"0x42EC8d1eB7CD9d3EcC91989DF6a093735B3A2F33"}
                            onCopy={handleCopyMessage}
                        >
                            <div>
                                <Button
                                    title={<CopyIcon />}
                                    height={56}
                                    heightResponsive={56}
                                    heightResponsive580={56}
                                    width={56}
                                    color="#fff"
                                    outlineColor="rgba(0,0,0,0.1)"
                                    bgColor="linear-gradient(90deg, #BC7738 0%, #9130BB 100%)"
                                    hoverColor="#fff"
                                    bgHoverColor="#08FF9B"
                                    customClass="z-2 radius16 font-ri-m font-size-20 position-absolute button-center copy-btn"
                                    minWidth={56}
                                    callback={() => {}}
                                />
                            </div>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmartContract;
